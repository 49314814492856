import { navigateToUrl, registerApplication, start } from "single-spa";
import "../static/css/site.css";
import {
  MARKETING_UI_URL,
  CATALOG_UI_URL,
  COURSE_UI_URL,
  SECTION_UI_URL,
  ENROLLMENT_UI_URL,
  GRADING_UI_URL,
  LICENSING_UI_URL,
  ATTENDANCE_LOG_UI_URL,
  AUTHORING_UI_URL,
  SESSION_LOG_UI_URL,
  DCO_UI_URL,
  ACCESS_TOKEN_KEY,
  EDGE_TOKEN_KEY,
  IMPERSONATION_BANNER_UI_URL,
  BULKFILE_UI_URL
} from "./app-config";
import { decodeToken, getAccessTokenAsync, getAccessToken, getLmsBaseUrl, isDecodedTokenValid } from "./utils/auth";
import { setupManageActiveSession } from "./active-session";
import { importRemote } from "./microfrontends/import-remote";
import { loadScript } from "./microfrontends/load-script";
import { loadSettingsV2 } from "./microfrontends/load-settings";
import { generateDocTitle, preventReloadOnAbsoluteUrl } from "./utils/window";
import { deleteCookie, getCookie } from "./utils/cookie";

loadConfigurations().then(() => {
  registerApplication(
    "marketing",
    () => importRemote(`${MARKETING_UI_URL}/cwng-marketing-mf.js`, "marketing", "./single-spa"),
    (location) => /(^\/catalog\/?$)/.test(location.pathname),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "catalog",
    () => importRemote(`${CATALOG_UI_URL}/cwng-catalog-mf.js`, "catalog", "./single-spa"),
    (location) => location.pathname.startsWith("/catalog"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "attendance_log",
    () => importRemote(`${ATTENDANCE_LOG_UI_URL}/cwng-attendance_log-mf.js`, "attendance_log", "./single-spa"),
    (location) => location.pathname.startsWith("/attendance-log"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );
  
  registerApplication(
    "authoring",
    () => importRemote(`${AUTHORING_UI_URL}/cwng-authoring-mf.js`, "authoring", "./single-spa"),
    (location) => location.pathname.startsWith("/authoring"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "session_log",
    () => importRemote(`${SESSION_LOG_UI_URL}/cwng-session_log-mf.js`, "session_log", "./single-spa"),
    (location) => location.pathname.startsWith("/session-log"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "dco",
    () => importRemote(`${DCO_UI_URL}/cwng-dco-mf.js`, "dco", "./single-spa"),
    (location) => location.pathname.startsWith("/default-course-options"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "course",
    () => importRemote(`${COURSE_UI_URL}/cwng-course-mf.js`, "course", "./single-spa"),
    (location) => location.pathname.startsWith("/course"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "section",
    () => importRemote(`${SECTION_UI_URL}/cwng-section-mf.js`, "section", "./single-spa"),
    (location) =>
      location.pathname.startsWith("/section") ||
      location.pathname.startsWith("/sections"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "enrollment",
    () => importRemote(`${ENROLLMENT_UI_URL}/cwng-enrollment-mf.js`, "enrollment", "./single-spa"),
    (location) =>
      location.pathname.startsWith("/enrollment") ||
      location.pathname.startsWith("/enrollments"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "grading",
    () => importRemote(`${GRADING_UI_URL}/cwng-grading-mf.js`, "grading", "./single-spa"),
    (location) => location.pathname.startsWith("/grade-book"),
    { getAuthToken: getAccessToken, getAuthTokenAsync: getAccessTokenAsync }
  );

  registerApplication(
    "header",
    // @ts-ignore
    () => import("@edgenuity/header"),
    (location) => location.pathname.startsWith("/"),
    { absoluteLinkBehavior: preventReloadOnAbsoluteUrl }
  );

  registerApplication(
    "footer",
    // @ts-ignore
    () => import("@edgenuity/footer"),
    (location) => location.pathname.startsWith("/")
  );

  registerApplication(
    "impersonation-banner",
    // @ts-ignore
    () =>
      importRemote(
        `${IMPERSONATION_BANNER_UI_URL}/cwng-impersonation-banner-mf.js`,
        "impersonationBanner",
        "./single-spa"
      ),
    (location) => location.pathname.startsWith("/"),
    {
      getAuthToken: getAccessToken,
      getLmsBaseUrl: getLmsBaseUrl,
      decodeToken: decodeToken,
    }
  );

  start();
});

window.onload = () => {
  if (location.pathname === "/") {
    navigateToUrl("/catalog");
  }

  setupManageActiveSession().catch(err => {
    console.log(err.message);
  });

  document.title = generateDocTitle(location.pathname);

  const encodedSymmetricToken = getCookie(EDGE_TOKEN_KEY);
  const decodedSymmetricToken = decodeToken(encodedSymmetricToken ?? "");
  const encodedAsymmetricToken = getCookie(ACCESS_TOKEN_KEY);
  const decodedAsymmetricToken = decodeToken(encodedAsymmetricToken ?? "");

  if (decodedSymmetricToken == null || decodedAsymmetricToken == null) {
    navigateToUrl(window.APP_ENV.LOGIN_PAGE_URL);
  } else if (
    !isDecodedTokenValid(decodedSymmetricToken) ||
    !isDecodedTokenValid(decodedAsymmetricToken)
  ) {
    deleteCookie(ACCESS_TOKEN_KEY);
    deleteCookie(EDGE_TOKEN_KEY);

    let logoutUrl =
      window.APP_ENV.LOGIN_PAGE_URL ??
      decodedSymmetricToken.LogoutUrl ??
      decodedAsymmetricToken.LogoutUrl;
    navigateToUrl(logoutUrl);
  }
};

async function loadConfigurations() {

  return Promise.all([
    CATALOG_UI_URL.includes("courseware") ?
        loadSettingsV2("CATALOG_ENV", `${CATALOG_UI_URL}/settings/env.json`) :
        loadScript(`${CATALOG_UI_URL}/env/catalog.env.js`),
    COURSE_UI_URL.includes("courseware") ?
        loadSettingsV2("COURSE_ENV", `${COURSE_UI_URL}/settings/env.json`) :
        loadScript(`${COURSE_UI_URL}/env/course.env.js`),
    SECTION_UI_URL.includes("courseware") ?
        loadSettingsV2("SECTION_ENV", `${SECTION_UI_URL}/settings/env.json`) :
        loadScript(`${SECTION_UI_URL}/env/section.env.js`),
    ENROLLMENT_UI_URL.includes("courseware") ?
        loadSettingsV2("ENROLLMENT_ENV", `${ENROLLMENT_UI_URL}/settings/env.json`) :
        loadScript(`${ENROLLMENT_UI_URL}/env/enrollment.env.js`),
    GRADING_UI_URL.includes("courseware") ?
        loadSettingsV2("GRADING_ENV", `${GRADING_UI_URL}/settings/env.json`) :
        loadScript(`${GRADING_UI_URL}/env/grading.env.js`),
    LICENSING_UI_URL.includes("courseware") ?
        loadSettingsV2("LICENSING_ENV", `${LICENSING_UI_URL}/settings/env.json`) :
        loadScript(`${LICENSING_UI_URL}/env/licensing.env.js`),
    ATTENDANCE_LOG_UI_URL.includes("courseware") ?
        loadSettingsV2("ATTENDANCE_LOG_ENV", `${ATTENDANCE_LOG_UI_URL}/settings/env.json`) :
        loadScript(`${ATTENDANCE_LOG_UI_URL}/env/attendance-log.env.js`),
    AUTHORING_UI_URL.includes("courseware") ?
        loadSettingsV2("AUTHORING_ENV", `${AUTHORING_UI_URL}/settings/env.json`) :
        loadScript(`${AUTHORING_UI_URL}/env/authoring.env.js`),
    DCO_UI_URL.includes("courseware") ?
        loadSettingsV2("DCO_ENV", `${DCO_UI_URL}/settings/env.json`) :
        loadScript(`${DCO_UI_URL}/env/dco.env.js`),
    BULKFILE_UI_URL.includes("courseware") ?
        loadSettingsV2("BULKFILE_ENV", `${BULKFILE_UI_URL}/settings/env.json`) :
        loadScript(`${BULKFILE_UI_URL}/env/bulkfile.env.js`),
    IMPERSONATION_BANNER_UI_URL.includes("courseware") ?
        loadSettingsV2("EDUCATOR_APPLICATION_BANNER_ENV", `${IMPERSONATION_BANNER_UI_URL}/settings/env.json`) :
        loadScript(`${IMPERSONATION_BANNER_UI_URL}/env/educator-application-banner.env.js`),
  ]);
}