export function loadSettingsV2(
    windowVar: string,
    jsonSrc: string
): Promise<void> {
  return fetch(jsonSrc)
      .then((response) => response.json())
      .then((data) => {
        // @ts-ignore
        window[windowVar] = Object.freeze(data);
      });
}
